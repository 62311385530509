<template>
  <v-container class="container">
    <template>
    <v-card class="profile-card py-4 px-6">
      <v-row>
        <v-col class="card-heading text-uppercase"> Invite member </v-col>
      </v-row>
        <!-- <v-divider> </v-divider> -->
      <v-form class="register-form pt-3" ref="form" @submit="submitForm">
        <v-row class="px-4">
          <v-col cols="12" sm="6" md="5" class="py-1">
            <BaseInput
              name="firstName"
              outlined
              label="First Name*"
              dense
              flat
              v-model="user.firstName"
              :status="errors('firstName').length ? 'error': 'normal'"
              :error-messages="errors('firstName')"
              @input="$v.user.firstName.$touch()"
              @blur="$v.user.firstName.$touch()"
            >
            </BaseInput>
          </v-col>
          <v-col cols="12" sm="6" md="5" class="py-1">
            <BaseInput
              name="lastName"
              outlined
              label="Last Name*"
              dense
              v-model="user.lastName"
              :status="errors('lastName').length ? 'error': 'normal'"
              :error-messages="errors('lastName')"
              @input="$v.user.lastName.$touch()"
              @blur="$v.user.lastName.$touch()"
            >

            </BaseInput>
          </v-col>
        </v-row>
        <v-row  class="px-4">
          <v-col cols="12" sm="6" md="5" class="py-1">
            <BaseInput
              name="email"
              outlined
              label="Email*"
              dense
              v-model="user.email"
              :status="errors('email').length ? 'error': 'normal'"
              :error-messages="errors('email')"
              @input="$v.user.email.$touch()"
              @blur="$v.user.email.$touch()"
            >

            </BaseInput>
          </v-col>
          <v-col cols="12" sm="6" md="5" class="py-1">
            <!-- <label class="text-left d-block input-field-label">Role*</label> -->
            <v-select
              dense
              flat
              outlined
              :items="options"
              v-model="user.role"
              label="Role*"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="d-flex justify-end pt-0" cols="12" md="10">
            <v-btn :loading="loading"
              class="pl-10 pr-10 btn-purple" type="submit">
              Add
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card class="profile-card py-4 px-6 mt-3">
      <v-row>
        <v-col class="card-heading text-uppercase"> Members </v-col>
      </v-row>
        <!-- <v-divider> </v-divider> -->
      <v-row>
        <v-col cols="12">
          <v-list two-line class="member-list pt-0">
            <template v-for="(user, index) in users">
              <v-row :key="user.email">
                <v-col cols="12" sm="6" md="7" lg="9" class="py-0">
                  <v-list-item
                    :key="user.email"
                    class="d-flex align-center pa-0"
                  >
                    <v-list-item-avatar size="60" class="my-2">
                      <v-avatar tile size="50" class="ma-0 d-flex avatar">
                        <v-img :src="profilePic(user)"
                          :class="{'no-image': !user.profilePic}"
                        > </v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="user.email"></v-list-item-title>
                      <v-list-item-subtitle class='notranslate'>
                        {{user.firstName}} {{user.lastName}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" xs="8" sm="6" md="5" lg="3" class="py-0 pr-4">
                  <v-list-item
                    :key="user.email"
                    class="d-flex align-center pa-0"
                  >
                    <v-list-item-action>
                      <v-list-item-title>
                        <v-select
                          solo
                          dense
                          flat
                          outlined=""
                          class="member-width"
                          :items="options"
                          :value="user.role.name"
                          label="Select Role"
                          hide-details
                          @change="updateRole($event, user)"
                          :disabled="currentUserId == user.id"
                        ></v-select>
                      </v-list-item-title>
                    </v-list-item-action>
                    <v-btn
                    dense
                    solo
                    style="min-width: 30px;"
                    @click="openDeleteConfirm(user.id)"
                    :disabled="currentUserId == user.id">
                      <v-icon color="#ffffff">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider
                v-if="index < users.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <template v-if="showDeleteConfirm">
      <v-dialog
        v-model="showDeleteConfirm"
        max-width="500"
      >
        <v-card class="modal-card pa-4" flat>
          <v-card-title class="black--text">
           Are you sure do you really want to delete ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text
            @click="showDeleteConfirm = false"
            class="py-0"
            color="primaryGray1"
          >
            Cancel
          </v-btn>
          <v-btn text color="info"
            type="submit"
            class="pa-0 ml-2"
            @click="deleteUser(selectedUserId)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
    </template>
    <!-- <template v-else>
      <v-row class="ma-0">
          <v-col cols="10" offset="1" offset-sm="3" sm="6">
            <v-img
              class="empty-profile-image"
              width="100%"
              src="@/assets/svg/theme/empty-profile.svg"
              alt="empty profile"
            />
            <div class="text text-center context-text mt-4">Nothing to see here!</div>
            <v-btn class="d-flex complete-profile-button" to="/dashboard">
              Complete Your Business Profile
            </v-btn>
          </v-col>
        </v-row>
    </template> -->
  </v-container>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import {
  required, email, maxLength,
} from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';

export default {
  components: {
    BaseInput,
  },
  watch: {
    async business(val) {
      this.users = await this.getBusinessUsers({ businessId: val.id });
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('business', ['business']),
    currentUserId() {
      return this.userDetails.id;
    },
    userList() {
      return this.users;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.user[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.user.firstName.required) {
              errors.push('Please provide first name.');
            }
            if (!this.$v.user.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.user.lastName.required) {
              errors.push('Please provide last name.');
            }
            if (!this.$v.user.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.user.email.required) {
              errors.push('Please provide email address.');
            }
            if (!this.$v.user.email.email) { errors.push('Please provide a valid email address.'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },

  data() {
    return {
      loading: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'member',
      },
      options: [
        {
          text: 'Owner',
          value: 'client',
        },
        {
          text: 'Member',
          value: 'member',
        },
      ],
      selectedUserId: null,
      users: [],
      showDeleteConfirm: false,
    };
  },

  methods: {
    ...mapActions('user', ['addMember', 'updateMember', 'deleteMember']),
    ...mapActions('business', ['getBusinessUsers']),
    profilePic(member) {
      const { profilePic } = member;
      if (profilePic) { return profilePic; }
      return require('@/assets/svg/users.svg');
    },

    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const result = await this.addMember({
          ...this.user,
          businessId: this.business.id,
        });
        if (result.success) {
          this.users.push(result.user);
          this.$v.$reset();
        }
        this.loading = false;
      }
    },
    async updateRole(role, user) {
      await this.updateMember({
        userId: user.id,
        role,
        businessId: this.business.id,
      });
      // if (result.success) {
    },
    openDeleteConfirm(id) {
      this.selectedUserId = id;
      this.showDeleteConfirm = true;
    },
    async deleteUser(userId) {
      const result = await this.deleteMember({
        userId,
        businessId: this.business.id,
      });
      if (result.success) {
        this.showDeleteConfirm = false;
        this.users = this.users.filter((user) => user.id !== userId);
      }
    },
  },

  validations: {
    user: {
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
    },
  },
  async mounted() {
    if (this.business.id) {
      this.users = await this.getBusinessUsers({ businessId: this.business.id });
    }
  },
};
</script>

<style scoped lang="scss">
  .member-list {
    ::v-deep .v-list-item {
      cursor: default;
    }
  }
  ::v-deep .input-field-label {
    font-size: 14px !important;
  }
  .v-image {
    border-radius: 18px;
    &.no-image {
      border: 2px solid $card-title;
    }
  }
  .empty-profile-image {
      margin-top: 75px;
    }
    .context-text {
      font-size: 24px;
      letter-spacing: -0.48px;
    }
    .complete-profile-button {
      margin: auto;
      height: 50px !important;
      width: 300px;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.5);
      background-color: $primary5 !important;
      color: $neutral6;
      margin-top: 20px;
      ::v-deep .v-btn__content {
        font-size: 16px;
      }
    }
    .member-width {
      width: 180px;
    }
    @media (max-width: 600px) {
      .container {
        padding-top: 60px;
      }
    }
</style>
